$background-color: #1b1b1b;
$white: #fff;
$yellow-font: #fecb3d;
$primary-color: #f5efe1;

@font-face {
  font-family: "Moniqa-Italic";
  src: url("fonts/moniqa-test.ttf") fromat("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600;700&display=swap");

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body {
  background-color: $background-color !important;
  color: $white !important;
  .example::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
  }
  .relative {
    position: relative;
  }
  .no-pointer-event {
    pointer-events: none;
  }
  .absolute {
    position: absolute;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .width-100 {
    width: 100vw;
  }
  .height-20-rem {
    height: 20rem;
  }
  .height-10-rem {
    height: 10rem;
  }
  .mb-10 {
    margin-bottom: 5rem;
  }

  .flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .col {
    flex-direction: column;
  }
  .center {
    align-items: center;
    justify-content: center;
  }

  .noise {
    height: 100vh;
    pointer-events: inherit;
    left: 0;
    top: 0;
    top: 0;
    width: 100vw;
    z-index: 10;
  }
  @keyframes grain {
    0%,
    100% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(-5%, -10%);
    }
    20% {
      transform: translate(-15%, 5%);
    }
    30% {
      transform: translate(7%, -25%);
    }
    40% {
      transform: translate(-5%, 25%);
    }
    50% {
      transform: translate(-15%, 10%);
    }
    60% {
      transform: translate(15%, 0%);
    }
    70% {
      transform: translate(0%, 15%);
    }
    80% {
      transform: translate(3%, 35%);
    }
    90% {
      transform: translate(-10%, 10%);
    }
  }
  .noise:after {
    content: "";
    pointer-events: inherit;
    background-image: url("./noise.jpg");
    animation: grain 8s steps(10) infinite;
    height: 300%;
    left: -50%;
    opacity: 0.045;
    position: fixed;
    top: -110%;
    width: 300%;
  }
  .title {
    font-family: "Moniqa-Italic" !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $yellow-font;
    font-weight: 600;
    font-size: calc(0.1rem + 20vw);
    line-height: 30rem;
    font-style: italic;
    text-align: center;
    letter-spacing: 0.5rem;
    // padding: 10% 10vw 10% 10vw;
    height: 100vh;
    word-wrap: break-word;
  }
  .loading-text {
    width: 60vw;
    padding: 10%;
    border: 4px solid $yellow-font;
  }

  @keyframes fadeInTitle {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .intro-title {
    font-size: calc(1.5rem + 3vw);
    color: $primary-color;
    font-family: "Inter" !important;
    z-index: 2;
    margin-left: calc(0px + 3vw);
    top: 0;
    -webkit-text-stroke-width: 0.06rem;
    -webkit-text-stroke-color: $primary-color;
    color: transparent;
    animation: fadeInTitle;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    // -webkit-text-stroke-width: 0.075rem;
    // -webkit-text-stroke-color: $background-color;
  }

  .intro-photo {
    right: 10vw;
    top: 5rem;
  }
}

.intro-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(3rem + 3vw);
  margin-bottom: calc(3rem + 3vw);
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.intro-gradient {
  width: 100%;
  align-self: center;
  max-width: calc(200px + 65vw);
  margin-top: calc(-0.35rem - 3.25vw);
  margin-bottom: calc(-0.35rem - 3.25vw);

  @keyframes gradient {
    0% {
      filter: brightness(0.45);
      opacity: 0;
    }

    100% {
      filter: brightness(1);
      opacity: 1;
    }
  }

  .gradient-text {
    font-size: calc(1.6rem + 8vw);
    letter-spacing: 0.2rem;
    color: $primary-color;
    vertical-align: baseline;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-wrap: unset;
    word-wrap: unset;
    white-space: nowrap;
    font-family: "Raleway", sans-serif !important;
    font-weight: 600;
    opacity: 0;

    // background: -webkit-linear-gradient(
    //   $background-color 25%,
    //   $primary-color 50%
    // );
    animation: gradient;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    background: -webkit-linear-gradient(
      $background-color 25%,
      $primary-color 50%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @keyframes statementText {
    from {
      color: $primary-color;
    }
    to {
      -webkit-text-stroke-width: 0.045rem;
      -webkit-text-stroke-color: $primary-color;
      color: $background-color;
    }
  }
  .statement {
    font-size: calc(1.5rem + 0.5vw);
    color: $primary-color;
    font-family: "Inter" !important;

    padding: calc(1.75rem + 0.5vw);
    width: 75%;
    min-width: #{"min(100vw, 350px)"};
    max-width: 1092px;
    text-align: center;
    margin-top: calc(1rem + 6vw);
    margin-bottom: calc(5rem + 6vw);
    animation: fadeInTitle;
    animation-duration: 2s;
    animation-delay: 3s;
    opacity: 0;
    animation-fill-mode: forwards;
  }
  b {
    -webkit-text-stroke-width: 0rem;
    -webkit-text-stroke-color: $primary-color;
    color: $primary-color;
  }
  b.seen {
    animation: statementText;
    animation-delay: 0.5s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }

  @keyframes quotes {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .quote1 {
    opacity: 0;
    font-size: 2rem;
    color: $primary-color;
    font-family: "Inter" !important;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
  }
  .quote1.seen {
    animation: quotes;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .quote2 {
    opacity: 0;
    font-size: 2rem;
    color: $primary-color;
    font-family: "Inter" !important;

    bottom: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
  }

  .quote2.seen {
    animation: quotes;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
  .list-header {
    font-family: "Inter", sans-serif;
    font-size: calc(1.2rem + 0.75vw);
    margin-top: 5rem;
    color: $primary-color;
  }
  .skill-list {
    width: 80%;
    max-width: 1000px;
  }

  @keyframes bumpup {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    80% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .skill-point {
    background-color: transparent;
    // border-radius: 2rem;
    opacity: 0;
    z-index: 20;
    color: $primary-color;
    border: 2px solid $primary-color;
    padding: 0.5rem 0.9rem;
    margin: 0.5rem;
    font-size: calc(0.75rem + 0.4vw);
    font-family: "Inter", sans-serif;

    // -webkit-transition: 1s;
  }
  .skill-point.seen {
    animation: bumpup;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes fadein {
    from {
      background-color: rgba(245, 239, 225, 0);
      color: rgba(245, 239, 225, 1);
    }
    to {
      background-color: rgba(245, 239, 225, 1);
      color: $background-color;
    }
  }
  .skill-point:hover {
    // animation: fadein;
    // animation-duration: 0.75s;
    // animation-fill-mode: forwards;
  }
}

.contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: $primary-color;

  .header {
    font-family: "Moniqa-Italic";
    font-size: 2.75rem;
  }
  .sub-header {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.45rem;
    padding-bottom: 0.3rem;
    border-bottom: 0.2rem solid $primary-color;
    font-family: "Inter";
    cursor: pointer;
  }
  .thank-you {
    font-size: 1.25rem;
    font-family: "Moniqa-Italic";
  }

  input {
    background-color: transparent;
    border: 2px solid $primary-color;
    padding: 0.75rem;
    width: 75%;
    max-width: 600px;
    outline: none;
    margin-bottom: 0.5rem;
    color: $primary-color;
  }
  input:focus {
    outline: none;
  }
  .textbox {
    background-color: transparent;
    border: 2px solid $primary-color;
    padding: 0.75rem;
    width: 75%;
    max-width: 600px;
    outline: none;
    margin-bottom: 0.5rem;
    color: $primary-color;
    resize: none;
    overflow: auto;
  }
  .textbox:focus {
    outline: none;
  }
  .textbox::-webkit-scrollbar {
    display: none;
  }
}
