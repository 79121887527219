@import url("//fonts.googleapis.com/css2?family=Signika:wght@520&display=swap");
@import url('//fonts.googleapis.com/css2?family=Merriweather:wght@900&family=Raleway:wght@900&display=swap');
@import url('//fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

.main-nav {
    color: transparent;
}

.ctn{
    margin-right : 0 !important;
    margin-left : 0 !important;
    padding-right : 0 !important;
    padding-left : 0 !important;
}

.nav{
    margin-top: 15px;
    margin-right: 30px;
}

.main-nav-active {
    color: tomato;
}
.nav-content{
    width:100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
    padding-top: 15px;
    
    
}


.logo{
    font-family: 'Raleway', sans-serif;
    color: #212529;;
    font-size: 48px;
    
    margin-left: 20px;
    padding-top: 15px;
    /* background-color: white; */
    /* letter-spacing: 5px; */
    
}

.nav-items{
    padding-top: 15px;
    vertical-align:middle;
    /* align-items: center;
    justify-content: center; */
}
.nav-item{
    vertical-align:middle;
    float:right;
    font-family: 'Raleway', sans-serif;
    /* background-color: black; */
    font-size: calc(14px + 0.1vw);
    
    color: white;
    text-align: center;
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    letter-spacing: 3.5px;

    text-decoration: none;
}

.extraleft{
    padding-left: 10px;
}


.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: transparent;
}


.nav-item:hover{
    color: #212529;
}

